export default function (vara) {
  let window, title
  if (typeof vara !== 'undefined' && vara) {
    window = vara.id ? `vara.${vara.id}` : 'vara.new'
    title = 'Visualizar Vara'
  } else {
    window = 'vara.new'
    title = 'Nova Vara'
  }

  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: title,
    width: '800',
    height: '70%',
    minWidth: '800px',
    minHeight: '600px',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
      id: vara?.id
    }
  }, () => import('./Vara'))
      .then((wid) => {
        console.log(wid)
        this.$uloc.window.listen(wid, {
          update: (wid, val) => {
            console.log('Atualizado: ', val)
            this.updateList(val)
          }
        })
      }) // return wid
}
